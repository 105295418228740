<!--
  PACKAGE_NAME : src/pages/euc/device/setting/
  FILE_NAME : index.vue
  AUTHOR : jhsim
  DATE : 2024-04-19
  DESCRIPTION :
-->
<template>
  <div class="container">
    <Tabs ref="tabs" @selectedIndex="tabSelectedIndex" :tabType="1">
      <template v-for="(item, index) in getComponent">
        <Tab :title="item.title" :key="`Tab${index}`">
          <div v-if="tabIndex === index" class="locker_setting_list sub_new_style01 sub_ui_box1">
            <div class="cusmain-table-wrap">
              <component :is="item.component" :cmList="cmList" />
            </div>
          </div>
        </Tab>
      </template>
    </Tabs>
  </div>
</template>
<script>
  import Tabs from '@/components/common/tabs.vue';
  import Tab from '@/components/common/tab.vue';
  import CssList from '@/pages/euc/setting/cssList.vue';
  import RoutePartitionList from '@/pages/euc/setting/routePartitionList.vue';
  import DevicePoolList from '@/pages/euc/setting/devicePoolList.vue';
  import SoftKeyList from '@/pages/euc/setting/softKeyList.vue';
  import PhoneButtonList from '@/pages/euc/setting/phoneButtonList.vue';
  import ExpansionList from '@/pages/euc/setting/expansionList.vue';
  import ModelList from '@/pages/euc/setting/modelList.vue';
  import { isSuccess } from '@/plugins/common-lib';

  export default {
    components: {
      Tabs,
      Tab,
      CssList,
      RoutePartitionList,
      DevicePoolList,
      SoftKeyList,
      PhoneButtonList,
      ExpansionList,
      ModelList,
    },
    props: {},
    watch: {},
    data() {
      return {
        tabIndex: 0,
        cmList: [],
        tabs: {
          selectedIndex: this.$store.getters.getTabHists[this.$router.currentRoute.path],
        },
        // componentProps: {
        // 	cmList: this.cmList,
        // },
        config: {
          stylingMode: 'outlined', //outlined, underlined, filled
          pageSetting: {
            //pageData pageSetting 관련
            config: {},
            formData: {},
          },
        },
        tabItems: [
          {
            title: 'CSS 관리',
            component: CssList,
            vender: ['CISCO'],
          },
          {
            title: 'PARTITION 관리',
            component: RoutePartitionList,
            vender: ['CISCO'],
          },
          {
            title: 'DEVICEPOOL 관리',
            component: DevicePoolList,
            vender: ['CISCO'],
          },
          {
            title: 'SOFTKEY 관리',
            component: SoftKeyList,
            vender: ['CISCO'],
          },
          {
            title: 'PHONEBUTTON 관리',
            component: PhoneButtonList,
            vender: ['CISCO'],
          },
          {
            title: 'MODEL 관리',
            component: ModelList,
            vender: ['CISCO'],
          },
          {
            title: 'EXPANSION 관리',
            component: ExpansionList,
            vender: ['CISCO'],
          },
        ],
      };
    },
    computed: {
      getVender() {
        return this.$_getSystemData('vender')?.configValue || 'CISCO';
      },
      getComponent() {
        return this.tabItems.filter(item => item.vender.includes(this.getVender));
      },
    },
    methods: {
      /** @description : 라이프사이클 mounted시 호출되는 메서드 */
      async mountData() {
        // await this.selectCmList();
      },
      /** @description : 라이프사이클 created시 호출되는 메서드 */
      async createdData() {
        await this.selectCmList();
        // this.dataGrid.showActionButtons.customButtons = this.getCustomButtons();
      },
      async tabSelectedIndex(index) {
        // cm data 변경 시 aes, svr에서 반영할 수 있도록 하기 위해 v-if 사용
        this.tabIndex = index;
      },
      /**@description 교환기 목록 가져오는 메서드 */
      async selectCmList(sort = '+cmOrd') {
        const params = { sort: sort, viewFl: 'Y' };
        const payload = {
          actionname: 'EUC_CM_LIST_ALL',
          data: params,
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          this.cmList = res.data.data;
        }
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      // this.mountData();
    },
    destroyed() {},
  };
</script>

<style scoped></style>
