<template>
  <div class="locker_setting_list sub_new_style01 sub_ui_box1">
    <Search
      codeKey="null"
      :customSearchList="searchType.list"
      :searchObj="searchType.obj"
      @change-search-type="$_changeSearchType"
      @onSearchClick="searchData($event)"
    >
    </Search>
    <esp-dx-data-grid :data-grid="dataGrid" ref="cssGrid" />
  </div>
</template>

<script>
  import Search from '@/components/common/search.vue';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxSwitch } from 'devextreme-vue/switch';
  import { isSuccess } from '@/plugins/common-lib';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: {
      EspDxDataGrid,
      DxSelectBox,
      DxSwitch,
      Search,
    },
    props: {
      cmList: {
        type: Array,
      },
    },
    data() {
      return {
        config: {
          pageSetting: {
            //pageData pageSetting 관련
            config: {},
          },
        },
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          list: [
            {
              codeNm: this.$_msgContents('COMPONENTS.NAME'),
              codeValue: 'name',
            },
            {
              codeNm: this.$_msgContents('COMPONENTS.DESCRIPTION'),
              codeValue: 'description',
            },
          ],
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {},
          paramsData: null,
        },
        dataGrid: {
          callApi: 'CALL_EUC_API',
          refName: 'cssGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          height: 'calc(100vh - 350px)', // 주석처리시 100%
          apiActionNm: {
            update: 'EUC_CSS_UPDATE',
            loading: true,
          },
          customEvent: {},
          showActionButtons: {
            customButtons: [],
          },
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: true,
            sorting: true,
            grouping: true,
            paging: true,
          },
          paging: {
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '교환기',
              i18n: 'UC.WORD.EXCHANGER',
              dataField: 'cmNm',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              calculateCellValue: row => this.getCmNm(row?.cmNm),
            },
            {
              caption: '이름',
              i18n: 'COMPONENTS.NAME',
              dataField: 'name',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowExporing: true,
              fixed: false,
            },
            {
              caption: '설명',
              i18n: 'COMPONENTS.DESCRIPTION',
              dataField: 'description',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '순서',
              i18n: 'COMPONENTS.ORDER',
              dataField: 'cssOrd',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              requiredRule: {
                message: '필수값입니다.',
                i18n: 'COMMON.REQUIRED_VALUE',
              },
            },
            {
              caption: '사용 여부',
              i18n: 'COMPONENTS.USE_STATUS',
              dataField: 'viewFl',
              width: 150,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              cellTemplate: (container, options) => {
                const switchBtn = new DxSwitch({
                  propsData: {
                    value: options.value === 'Y',
                    onValueChanged: () => {
                      this.onChangedViewFl(options.data);
                    },
                  },
                });
                switchBtn.$mount();
                container.append(switchBtn.$el);
              },
            },
            {
              caption: '수정일',
              i18n: 'COMPONENTS.MODIFY_DATE',
              dataField: 'editDt',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              calculateCellValue: row => this.formatDt(row?.editDt),
            },
            {
              caption: '등록일',
              i18n: 'COMPONENTS.REGISTRATION_DATE',
              dataField: 'regDt',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              calculateCellValue: row => this.formatDt(row?.regDt),
            },
          ],
        },
      };
    },
    computed: {},
    methods: {
      /** @description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {
        this.dataGrid.showActionButtons.customButtons = this.getCustomButtons();
      },
      /** @description : 라이프사이클 mounted시 호출되는 메서드 */
      mountData() {
        this.searchType.paramsData = null;
        this.selectDataList();
      },
      /**@description 사용여부 변경 이벤트 */
      async onChangedViewFl(data) {
        const viewFl = data.viewFl === 'Y' ? 'N' : 'Y';

        if (data.id) {
          const payload = {
            actionname: 'EUC_CSS_UPDATE',
            data: [{ id: data.id, viewFl }],
            loading: true,
          };

          const res = await this.CALL_EUC_API(payload);
          if (isSuccess(res)) {
            this.$_Toast(this.$_msgContents('CMN_SUC_UPDATE'));
            this.$refs.cssGrid.getGridInstance.refresh();
          } else {
            this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          }
        } else {
          data.viewFl = viewFl === 'Y';
        }
      },
      async selectDataList(sort = '+cssOrd , +id') {
        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);
            if (!params.sort) {
              params.sort = sort;
            }

            params = { ...params, ...this.searchType.paramsData };

            const payload = {
              actionname: 'EUC_CSS_LIST_ALL',
              data: params,
              loading: false,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_EUC_API(payload);
            if (isSuccess(res)) {
              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.header.totalCount;
            }
            return rtnData;
          },
        });
      },
      getCustomButtons() {
        const buttons = [
          {
            widget: 'dxButton',
            options: {
              icon: '',
              text: this.$_msgContents('UC.WORD.SYNC'),
              elementAttr: { class: 'btn_XS default filled ' },
              width: 80,
              height: 30,
              onClick: () => {
                this.onSyncCss();
              },
            },
            location: 'before',
          },
        ];

        return buttons;
      },
      /** @description : 날짜 형식 변환 메서드 */
      formatDt(date) {
        if (date) {
          return this.$_commonlib.formatDate(date, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
        }
      },
      /** @description : 교환기 이름 변환 메서드 */
      getCmNm(data) {
        let cmNm = '';
        for (let i = 0; i < this.cmList.length; i++) {
          if (this.cmList[i].cmNm === data) {
            cmNm = this.cmList[i].description;
          }
        }
        if (cmNm !== '' && cmNm !== null) {
          return cmNm;
        } else {
          return '';
        }
      },
      /**@description 발신검색 동기화 */
      async onSyncCss() {
        for (const item of this.cmList) {
          const params = { cmNm: item.cmNm, loginId: this.$store.getters.getLoginId };
          const payload = {
            actionname: 'EUC_CSS_SYNC',
            data: params,
            loading: true,
          };

          const res = await this.CALL_EUC_API(payload);
          if (isSuccess(res)) {
            // await this.$_Msg('교환기 : ' + item.cmNm + ' ' + this.$_msgContents('COMMON.MESSAGE.CMN_SUCCESS', { defaultValue: '정상적으로 처리되었습니다.' }));
            await this.$refs.cssGrid.getGridInstance.refresh();
          } else {
            if (res.data.data.length == 0) {
              await this.$_Msg(res.data.header.resMsg);
            } else {
              await this.$_Msg('교환기 : ' + item.cmNm + ' ' + this.$_msgContents('COMMON.MESSAGE.CMN_ERROR'));
            }
          }
        }
      },
      /**@description 커스텀 검색 이벤트 */
      searchData(paramsData) {
        if (paramsData) {
          this.searchType.paramsData = { ...this.searchType.paramsData, ...paramsData };
        } else {
          const codeValues = this.searchType.list.map(d => d.codeValue);
          if (this.searchType.paramsData != null) {
            if (Object.keys(this.searchType.paramsData).length > 0) {
              Object.keys(this.searchType.paramsData).forEach(key => {
                if (codeValues.includes(key)) {
                  delete this.searchType.paramsData[key];
                }
              });
            }
          }
        }
        this.selectDataList();
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountData();
    },
  };
</script>
